<template>
    <div class="flex flex-column justify-content-between border-round border-1 border-300 hover:border-primary">
        <RouterLink :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: process.id } }">
            <div class="p-3 w-full h-12rem flex align-items-center justify-content-center border-round-top cursor-pointer">
                <ImageStorm v-if="imageExist" :src="imageUrl" @error="imageExist = false" />
                <span :class="PrimeIcons.EXCLAMATION_TRIANGLE" class="text-7xl text-300" v-else></span>
            </div>
        </RouterLink>
        <div class="px-4">
            <Divider class="m-0" />
        </div>
        <div class="flex-auto flex flex-column gap-3 p-4">
            <div class="flex flex-column gap-2 align-items-start">
                <slot name="status"></slot>
                <div class="w-full flex gap-3 align-items-center justify-content-between">
                    <router-link
                        :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: process.id } }"
                        class="text-primary no-underline hover:bg-primary-100 cursor-pointer px-1 border-round"
                    >
                        <h3>{{ process.name }}</h3>
                    </router-link>
                    <slot name="menu">
                        <span :class="PrimeIcons.ELLIPSIS_H"></span>
                    </slot>
                </div>
            </div>
            <div class="flex flex-column gap-2 align-items-start">
                <div class="flex gap-2 align-items-center">
                    <Avatar :username="process.authorUsername" :size-img="ESizeImg.SMALL" />
                    {{ process.authorUsername }}
                </div>
                <div class="flex gap-2">
                    <span class="pi pi-calendar"></span>
                    {{ formatDateTime(process.createdOn) }}
                </div>
                <div class="flex gap-2 align-items-center">
                    <span class="pi pi-star"></span>
                    <Badge v-if="process.quality" :value="process.quality" severity="success"></Badge>
                    <span v-else>Нет оценки</span>
                </div>
                <div class="flex gap-2 align-items-center">
                    <span class="pi pi-folder"></span>
                    <FolderLabel v-if="process.teamFolderId" :label="getFolderName()" :show-icon="false" />
                    <span v-else>Без папки</span>
                </div>
            </div>
        </div>
        <!-- <div class="flex flex-column px-4 pb-4 relative">
            <Divider />
            <TagSelect v-model="tags" />
        </div> -->
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import { ERoutesName } from "@/app/providers";

import { formatDateTime } from "@/shared/lib/utils/date";
import { API_URL } from "@/shared/config";
import { Avatar } from "@/shared/ui/avatar/";

import { TagSelect, useTagStore, type ITag } from "@/entities/Tag";
import { type IProcess } from "@/entities/Process/model";
import { ImageStorm } from "@/shared/ui/image";
import { PrimeIcons } from "primevue/api";
import { FolderLabel } from "@/entities/Folder";
import { ESizeImg } from "@/shared/lib/types";

interface IProps {
    process: IProcess;
}

const tagStore = useTagStore();

const props = defineProps<IProps>();

const imageExist = ref<boolean>(true);
const tags = ref<ITag[]>(props.process.tags);

const imageUrl = computed<string>(() => `${API_URL}files/${props.process.id}_preview.png`);

function getFolderName(): string {
    return props.process.teamFolderName as string;
}

watch(
    tags,
    async () => {
        await tagStore.updateByDiagramId(props.process.id, tags.value);
    },
    { deep: true },
);
</script>

<style scoped></style>
