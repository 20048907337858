<template>
    <div :class="imgSize">
        <Image v-if="avatarExist" imageClass="border-circle" :src="avatarUrl" @error="avatarExist = false" />
        <span v-else class="pi pi-user"></span>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { API_URL } from "@/shared/config";
import { ESizeImg } from "@/shared/lib/types";

const avatarExist = ref(true);

type TProps = {
    username: String;
    sizeImg?: ESizeImg;
};

const props = withDefaults(defineProps<TProps>(), { sizeImg: ESizeImg.SMALL });

const avatarUrl = computed(() => `${API_URL}files/username/${props.username}`);
const imgSize = computed(() => {
    if (props.sizeImg == ESizeImg.BIG) {
        return "w-10rem h-10rem";
    } else if (props.sizeImg == ESizeImg.MEDIUM) {
        return "w-5rem h-5rem";
    }
    return "w-1rem h-1rem";
});
</script>
