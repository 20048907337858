import { emailRegexp } from "../regex";
import { declensionNumerals } from "../utils/formater";

export const emailValidator = (email: string): boolean => {
    return emailRegexp.test(email);
};

export const notEmail: string = "Значение не является адресом электронной почты";
export const requiredValue: string = "Обязательное поле";
export const passwordsNotMatch: string = "Пароли не совпадают";
export const notMinLength = (value: number): string => `Минимум ${value} ${declensionNumerals(value, ["символ", "символа", "символов"])}`;
