import { computed, ref } from "vue";
import { PrimeIcons } from "primevue/api";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { DOWNLOAD_NAME, IMG_DIRECTUS } from "@/shared/config";
import { formatDate } from "@/shared/lib/utils/date";
import { DEFAULT_SEO_CONFIG } from "@/shared/config/seo";
import { httpDirectus } from "@/shared/api";
import { useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";

import { useNotification } from "@/shared/lib/composables";
import type {
    IDirectusResponse,
    IDirectusNavigation,
    IDirectusNavigationItem,
    IResponsePayCourse,
    IResponsePromocode,
} from "@/shared/lib/types";
import type { IPayloadCourses, ISelectedTariff, ISeo, IGetElement, IGetContactUs, IStudents } from "./types";
import type { IGetTag, IgetBlogFeaturedPost, IGetArticleDaum, IgetBlogData, IPost } from "@/shared/lib/types";
import { EPostType } from "./types";

import { InterfaceApi } from "../api";

import { useSessionStore } from "@/entities/Session";
import { useInvoiceStore } from "@/entities/Invoice";

export const useInterfaceStore = defineStore("interfaceStore", () => {
    const { showError, showSuccess } = useNotification();
    const { t } = useI18n();
    const sessionStore = useSessionStore();
    const invoiceStore = useInvoiceStore();
    const router = useRouter();

    const pageData = ref();
    const seo = ref<ISeo>({
        title: "",
        meta_desciription: "",
        robots: "",
        og_image: "",
        og_title: "",
        og_description: "",
        og_url: "",
        og_type: "",
        canonical_url: "",
        og_sitename: "",
        json_schema: false,
        json_data: null,
    });
    const headerItem = ref<IDirectusNavigationItem[]>([]);
    const footerItem = ref<IDirectusNavigationItem[]>([]);
    const footerCopyright = ref<null | string>(null);
    const blogTags = ref<IGetTag[]>();
    const featuredPost = ref<IgetBlogFeaturedPost>();
    const article = ref<IGetArticleDaum>();
    const blog = ref<IgetBlogData>();
    const postVideo = ref<IPost | any>([]);
    const postText = ref<IPost | any>([]);
    const lastPostText = ref<IPost | any>([]);
    const activeIdAnchor = ref<string>("");
    const isObserver = ref<boolean>(false);
    const isSearch = ref<boolean>(false);
    const element = ref<IGetElement>();
    const contactUsPage = ref<IGetContactUs>();
    const seachNotResult = ref<boolean>(false);

    // computed для того, чтобы Администрирование показывать только админу
    const menuProfileItems = computed(() => {
        return [
            {
                label: "Профиль",
                icon: PrimeIcons.USER,
                link: "/app/profile",
            },
            {
                separator: true,
            },
            {
                label: "Администрирование",
                icon: PrimeIcons.COG,
                link: "/app/admin",
            },
            {
                separator: true,
            },
            {
                label: "Команда",
                icon: PrimeIcons.USERS,
                link: "/app/team/participants",
            },
            {
                separator: true,
            },
            {
                label: "Подписка и счета",
                icon: PrimeIcons.WALLET,
            },
            {
                label: "Улучшить подписку",
                icon: PrimeIcons.STAR_FILL,
                class: "priority",
            },
            {
                label: "Помощь",
                icon: PrimeIcons.QUESTION_CIRCLE,
            },
            {
                separator: true,
            },
            {
                label: "Выйти",
                icon: PrimeIcons.SIGN_OUT,
                command: () => sessionStore.logout(),
            },
        ];
    });

    function saveSeo(data: any) {
        seo.value.title = data.title ? data.title : DEFAULT_SEO_CONFIG.title;
        seo.value.meta_desciription = data.meta_desciription ? data.meta_desciription : DEFAULT_SEO_CONFIG.meta_desciription;
        seo.value.og_description = data.og_description ? data.og_description : DEFAULT_SEO_CONFIG.og_description;
        seo.value.og_title = data.og_title ? data.og_title : DEFAULT_SEO_CONFIG.og_title;
        seo.value.og_url = data.og_url ? data.og_url : data.canonical_url;
        seo.value.og_type = data.og_type ? data.og_type : DEFAULT_SEO_CONFIG.og_type;
        seo.value.canonical_url = data.canonical_url ? data.canonical_url : DEFAULT_SEO_CONFIG.canonical_url;
        seo.value.og_sitename = data.og_sitename ? data.og_sitename : DEFAULT_SEO_CONFIG.og_sitename;

        seo.value.robots = "all";

        seo.value.json_schema = data.json_schema ? data.json_schema : null;

        seo.value.json_data = data.json_data ? JSON.stringify(data.json_data) : null;

        if (data.og_image) {
            let ogImg = data.og_image;
            ogImg.id = IMG_DIRECTUS + ogImg.id;
            seo.value.og_image = ogImg.id;
        } else seo.value.og_image = DEFAULT_SEO_CONFIG.og_image;
    }
    function changeActiveIdAnchor(id: string) {
        activeIdAnchor.value = id;
    }
    function startObserver() {
        activeIdAnchor.value = "s0";
        isObserver.value = true;
        activeIdAnchor.value = "s0";
    }
    function stopObserver() {
        isObserver.value = false;
    }
    function stopSearch() {
        isSearch.value = false;
    }

    async function getHeader(): Promise<void> {
        try {
            const res: IDirectusResponse<IDirectusNavigation> = await InterfaceApi.getHeader();
            res.data.items[res.data.items.length - 1].last = true;
            headerItem.value = res.data.items;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getFooter(): Promise<void> {
        try {
            const res: IDirectusResponse<IDirectusNavigation> = await InterfaceApi.getFooter();
            footerItem.value = res.data.items;
            footerCopyright.value = res.data.copyright;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function applyPromocode(promocode: string) {
        try {
            const res: IDirectusResponse<IResponsePromocode[]> = await InterfaceApi.applyPromocode(promocode);
            if (res.data.length > 0) {
                return res.data[0].discount;
            } else {
                showError("Такого промокода не существует");
                return 0;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function payCoursePersonal(payload: IPayloadCourses) {
        try {
            const res: IDirectusResponse<IResponsePayCourse> = await InterfaceApi.payCoursePersonal(payload);
            return res.data.id;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getInvoiceMail(payload: IPayloadCourses, company: any, selectedTariff: ISelectedTariff) {
        try {
            const res: IDirectusResponse<IResponsePayCourse> = await InterfaceApi.getInvoiceMail(payload);
            if (res.data.id) {
                const students = []
                 for (let item of payload.students) students.push(item.email);
                const payloadCourse: any = {
                    amount: payload.total_price,
                    courseTitle:
                        selectedTariff.course.title +
                        " c " +
                        formatDate(selectedTariff.course.start_date) +
                        " по " +
                        formatDate(selectedTariff.course.end_date) +
                        ". Тариф " +
                        selectedTariff.plan.title +
                        " Количество участников: " +
                        students.length,
                    inn: payload.corporate_inn,
                    invoiceNumber: "COURSES #" + res.data.id,
                    legalEntityString: company.data?.name.full_with_opf || null,
                    students: students,
                    manager: company.data.management.name || null,
                    studentsCount: students.length,
                };
                const download = await invoiceStore.downloadInvoicesCourse(payloadCourse);
                const anchorElement = document.createElement("a");
                document.body.appendChild(anchorElement);
                let blob = new Blob([download], {
                    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                let url = window.URL.createObjectURL(blob);
                anchorElement.href = url;
                anchorElement.download = DOWNLOAD_NAME + "_" + company.data.name.short_with_opf + ".docx";
                anchorElement.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function fetchAll(link: string) {
        try {
            let responsePageData = await httpDirectus.get(`/Pages?filter[permalink][_eq]=${link}&fields=*.*.*.*.*.*.*`);
            if (responsePageData.data.data.length > 0) {
                pageData.value = responsePageData.data.data[0];
            } else {
                await router.replace({ name: ERoutesName.NOT_FOUND });
            }
            if (responsePageData.data.data[0].seo) {
                saveSeo(responsePageData.data.data[0].seo);
            }
            if (pageData.value.blocks.length == 0) return false;
            return true;
        } catch (e) {
            return false;
        }
    }

    async function getPosts() {
        try {
            seachNotResult.value = false;
            let responsePosts = await InterfaceApi.getPosts();
            if (responsePosts.data) {
                const posts = responsePosts.data;
                lastPostText.value = [];
                postText.value = [];
                postVideo.value = [];
                posts.map((item: any) => {
                    if (item.type == EPostType.BLOG) {
                        postText.value.push(item);
                    } else {
                        postVideo.value.push(item);
                    }
                });
                if (featuredPost.value) {
                    postText.value = postText.value.filter((item: any) => item.id != featuredPost.value?.id);
                }
                postText.value = postText.value.sort(
                    (a: any, b: any) => new Date(b.date_published).getTime() - new Date(a.date_published).getTime(),
                );
                lastPostText.value.push(postText.value.shift());
                lastPostText.value.push(postText.value.shift());
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getTags() {
        try {
            let responseTags = await InterfaceApi.getTags();
            blogTags.value = responseTags.data;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getBlog() {
        try {
            let responseBlog = await InterfaceApi.getBlog();
            if (responseBlog.data.seo) {
                saveSeo(responseBlog.data.seo);
            }
            if (!isSearch.value) {
                featuredPost.value = responseBlog.data.featured_post;
                blog.value = responseBlog.data;
            } else {
                featuredPost.value = undefined;
                isSearch.value = false;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getArticle(id: string) {
        try {
            const res = await InterfaceApi.getArticle(id);
            if (res.data[0]) {
                if (res.data[0].seo) {
                    saveSeo(res.data[0].seo);
                }
                article.value = res.data[0];
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getBlogByTag(tagId: string) {
        try {
            seachNotResult.value = false;
            const res = await InterfaceApi.getBlogByTag(tagId);
            if (res.data[0]) {
                if (res.data[0].seo) {
                    saveSeo(res.data[0].seo);
                }
                lastPostText.value = [];
                postText.value = [];
                postVideo.value = [];
                if (res.data[0].posts) {
                    postText.value = res.data[0].posts.sort(
                        (a: any, b: any) => new Date(b.date_published).getTime() - new Date(a.date_published).getTime(),
                    );
                }
                postText.value = postText.value.filter((item: any) => item.type == EPostType.BLOG);
                blog.value = res.data[0];
                featuredPost.value = undefined;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function searchPosts(string: string) {
        try {
            isSearch.value = true;
            const res = await InterfaceApi.searchPosts(string);
            if (res.data.length > 0) {
                lastPostText.value = [];
                postText.value = [];
                postVideo.value = [];
                featuredPost.value = undefined;
                postText.value = res.data.filter((item: any) => item.type == EPostType.BLOG);
            } else {
                seachNotResult.value = true;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getElement(slug: string) {
        try {
            const res = await InterfaceApi.getElement(slug);
            if (res.data[0]) {
                element.value = res.data[0];
                const urlStr = window.location.href;
                const seo = {
                    title: "Описание элемента BPMN: " + element.value.name,
                    meta_desciription: element.value.description,
                    canonical_url: urlStr,
                    og_title: "Описание элемента BPMN: " + element.value.name,
                    og_image: {
                        id: element.value.good_example_img.id,
                    },
                    og_description: element.value.description,
                    og_type: "article",
                    og_sitename: "StormBPMN - система моделирования бизнес-процессов",
                    json_data: "{}",
                };
                saveSeo(seo);
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getContactUs() {
        try {
            const res = await InterfaceApi.getContactUs();
            if (res.data) {
                contactUsPage.value = res.data;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    return {
        headerItem,
        footerItem,
        footerCopyright,
        menuProfileItems,
        pageData,
        seo,
        blogTags,
        featuredPost,
        article,
        blog,
        postVideo,
        postText,
        lastPostText,
        activeIdAnchor,
        isObserver,
        isSearch,
        element,
        contactUsPage,
        seachNotResult,

        getHeader,
        getFooter,
        applyPromocode,
        payCoursePersonal,
        getInvoiceMail,
        fetchAll,
        getPosts,
        getTags,
        getBlog,
        getArticle,
        getBlogByTag,
        searchPosts,
        changeActiveIdAnchor,
        startObserver,
        stopObserver,
        getElement,
        getContactUs,
        stopSearch,
    };
});
